import React, {useState, useLayoutEffect} from "react";
import Layout from "../layout";
import SetLang from "../components/setLang";
import Boxes from "../components/boxes";
import Hero from "../components/hero";
import Heading from "../components/heading";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import Parallax from "../components/parallax";
import Paragraph from "../components/asideParagrah";
import styled from "styled-components";
import { scale, rhythm } from "../utils/typography";
import Form from "../components/form";
import SEO from "../components/seo";
import ReactHtmlParser from "react-html-parser";
import { ContactWidget } from "../components/ContactWidget";
import { ContactPopUp } from "../components/contactPopUp";
import ButtonLink from "../components/buttonInformation";

const Block = styled.section`
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse !important;
  }
  hr {
    width: 20%;
    height: 4px;
    background: #333;
  }
  .paragraph,
  .list {
    display: flex;
    width: 100%;
    flex-basis: 50%;
    flex-direction: column;
    padding: ${rhythm(4)} 5vw ${rhythm(3)};
  }
  .list {
    background: #ededed;
    .icon {
      font-size: 50px;
      margin-bottom: ${rhythm(1)};
    }
    hr {
      width: 10%;
      background: #ededed;
    }
  }
  .paragraph {
    /*background: #ededed; */
    .map {
      max-width: 400px;
    }
    .title.big {
      h1 {
        ${scale(1.25)}
      }
    }
  }
`;
const Routes = styled.section`
  .message {
    border: 1px solid #333;
    padding: 3vw;
    text-align: center;
    max-width: 800px;
    margin: ${rhythm(1)} auto;
  }
  padding-bottom: ${rhythm(3)};
  padding-top: ${rhythm(4)};
  .icon-travel-wrapper {
    font-size: 145px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: ${rhythm(3)};
  }
  h1 {
    font-weight: 300;
    text-align: center;
    margin-bottom: ${rhythm(3)};
  }
  .departures {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 5vw;
    max-width: 1600px;
    margin: auto;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
    .departure {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-basis: 50%;
      flex-direction: row;
      margin-bottom: ${rhythm(2)};
      @media screen and (max-width: 1200px) {
        flex-basis: 100%;
      }
      .departures-logo {
        border-bottom: 2px solid #ededed;
      }
      .content {
        padding-left: 15%;
        padding-right: 15%;
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid #ededed;
        .visa {
          font-weight: bold;
          color: #333;
          margin-top: ${rhythm(1)};
        }
        p {
          color: #333;
          text-align: left;
        }
        .logo {
          font-size: 45px;
          margin-right: 20px;
        }
        .time {
          margin-right: 25% !important;
        }
        .time,
        .cost {
          display: flex;
          color: #333;
          margin-right: 0px;
          flex-flow: row-wrap;
          justify-content: center;
          align-items: center;
          font-weight: bold;
        }
        .visa {
          text-align: center;
        }
        .details {
          display: flex;
          flex-flow: row-wrap;
          text-align: left;
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }
      }
      .flag-icon {
        font-size: 60px;
      }
    }
  }
`;
const Gallery = styled.section`
  display: flex;
  background-color: #9a9a9a;
  flex-flow: row wrap;

  justify-content: center;
  width: 100%;
  margin: auto;
  padding: ${rhythm(4)} 5vw ${rhythm(4)};
  padding-bottom: 1.6rem;
  flex-direction: row;
  color: white;
  i.counter {
    font-style: normal;
    background: #222;
    display: flex;
    width: 50px;
    font-size: 40px;
    margin-top: -20px;
    margin-left: -20px;
    padding-top: 38px !important;
    padding: 30px 34px;

    height: 50px;
    position: absolute;
    border-radius: 50%;
    z-index: 2;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Bebas Neue Bold;
  }
  h1 {
    font-weight: 300;
    margin-bottom: ${rhythm(3)};
    width: 100%;
    text-align: center;
  }
  h3 {
    text-align: center;
    font-weight: 500;
  }
  .step {
    padding: 0 1vw;
    flex-direction: column;
    position: relative;
    margin-bottom: ${rhythm(3)};
    flex-basis: 33.33%;
    max-width: 480px;
    @media screen and (max-width: 1024px) {
      flex-basis: 50% !important;
    }
    @media screen and (max-width: 768px) {
      flex-basis: 100% !important;
    }
  }
  .gatsby-image-wrapper {
    position: relative;
    overflow: hidden;
    height: 100px;
    width: 100%;
    padding-bottom: 60%;
    border-radius: 30px;
  }
`;
const Prices = styled.section`
  display: flex;
  flex-flow: row wrap;
  padding: 0 2.5vw 0;
  margin: auto;
  max-width: 1600px;
  .converter {
    margin: auto;
    margin-bottom: ${rhythm(4)};

    .title{
      text-align: center;
    }
  }

  .link {
    min-width: 170px;
    white-space: nowrap;
    text-align: center;
    width: fit-content;
    cursor: pointer;
    font-weight: 700;
    padding: 10px 20px;
    color: #222 !important;
    border: 1px solid #222;
    text-transform: uppercase;
    background-color: #fff;
    text-decoration: none !important;
    margin-bottom: ${rhythm(1)} !important;
    &:hover {
      background-color: #222 !important;
      color: #fff !important;
    }
  }
  .price {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    width: 100%;
    padding: 0 2.5vw ${rhythm(2)};
    @media screen and (max-width: 768px) {
      flex-basis: 100% !important;
    }


    .price-link {
      min-width: 170px;
      white-space: nowrap;
      text-align: center;
      width: fit-content;
      cursor: pointer;
      font-weight: 700;
      padding: 10px 20px;
      color: #222 !important;
      border: 1px solid #222;
      text-transform: uppercase;
      background-color: #fff;
      text-decoration: none !important;
      margin-bottom: ${rhythm(1)} !important;
      &:hover {
        background-color: #222 !important;
        color: #fff !important;
      }
    }

    .title {
      width: 100%;
      background: #91c508;
      font-size: 26px;
      font-weight: 300;
      text-align: center;
      color: #fff;
      margin-bottom: 20px;
      padding: 20px 0;
    }
    .link-row {
      width: 100%;
      margin-bottom: ${rhythm(2)};
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .price-row {
      width: 100%;
      margin-bottom: 20px;
      background: #ededed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1vw 45px;
      margin-left: 0;
      margin-right: 0;

      .price {
        color: #91c508;
        font-weight: bold;
        ${scale(0.5)}
        padding: 0 0;
        align-items: flex-end;
        span {
          font-weight: 300;
          ${scale(-0.2)}
          width: fit-content;
          color: #555;
          text-align: right;
          max-width: 115px;
        }
      }
      p {
        font-weight: bold;
        color: #555;
        ${scale(0.12)}
        margin: 0;
      }
    }
    .icon-header {
      width: 100%;
      margin-bottom: 20px;
      background: #222;
      padding: 29px 0;
      text-align: center;
      i {
        background: black;
        color: white;
        font-size: 35px;
        border-radius: 50%;
        padding: 15px;
      }
    }
  }
`;

export const DentalTourismPageTemplate = ({
  language,
  blocksDescription,
  routes,
  gallerySteps,
  hero,
  heading,
  parallax,
  procedures,
  anexes,
  form,
  prices,
  forms,
}) => {
  return (
    <div>
      <Hero className="center single half" {...hero}></Hero>
      <Heading color="white" {...heading} />
      <Prices>
        {prices.rows.map((i, k) => {
          return (
            <div key={k} className="price">
              <div className="title">{i.title}</div>
              <span className="icon-header">
                <i className={`${i.icon}`}></i>
              </span>
              {i.rows.map((i, k) => {
                return (
                  <div key={k} className="price-row">
                    <span className="procedure">
                      <p>{ReactHtmlParser(i.procedure)}</p>
                    </span>
                    <span className="price">
                      {i.price}
                      <br></br>
                      <span> {i.currency}</span>
                    </span>
                  </div>
                );
              })}

              <div className="link-row">
                <ButtonLink href={i.link.to} title={i.link.title}/>
              </div>

            </div>
          );
        })}
        <div className="converter">
          <div className="title">{ReactHtmlParser(prices.footer.title)}</div>
          <a href={`${prices.footer.to}`}>
            <Img
              critical={true}
              fluid={prices.footer.image.childImageSharp.fluid}
              className="converter-img"
              style={{
                width: "50%",
                height: "50%",
                left: "20%"
              }}
            ></Img>
          </a>
        </div>
      </Prices>
      <Parallax {...parallax}></Parallax>
      <Routes>
        {ReactHtmlParser(routes.title)}

        <span className="icon-travel-wrapper">
          <i className="icon-travel">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
        </span>

        <div className="departures">
          {routes.departures.map((i, k) => {
            return (
              <div key={k} className="  departure">
                <div key={k} className=" departures-logo">
                  <span className={`flag-icon flag-icon-${i.flag}`}></span>
                </div>
                <div className="content">
                  <p>
                    <b>{i.from}</b>
                  </p>
                  <div className="details">
                    <div className="time">
                      <span className="logo ">
                        <i className="icon-clock"></i>
                      </span>
                      <span>{i.time}</span>
                    </div>
                    <div className="cost">
                      <span className="logo">
                        <i className="icon-currency"></i>
                      </span>
                      <span>{i.cost}</span>
                    </div>
                  </div>

                  <p className=" visa">{i.visa}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="message">{routes.footer}</div>
      </Routes>

      <Gallery>
        {ReactHtmlParser(gallerySteps.title)}
        {gallerySteps.steps.map((i, k) => {
          return (
            <div key={k} className="step">
              {ReactHtmlParser(i.title)}
              <span>
                <i className="counter">{k + 1}</i>
                <Img
                  critical={true}
                  fluid={i.image.childImageSharp.fluid}
                ></Img>
              </span>
            </div>
          );
        })}
      </Gallery>
      <Paragraph contained={true} {...anexes} />

      <Form
        type="extended"
        data={forms.specialties}
        title={form.title}
        language={language}
        img={form.background}
      ></Form>
      <Block>
        <div className="paragraph">
          {ReactHtmlParser(blocksDescription.sections.left.content)}
          <span className="map">
            <Img
              critical={true}
              fluid={
                blocksDescription.sections.left.image.childImageSharp.fluid
              }
            ></Img>
          </span>
        </div>
        <div className=" list">
          {blocksDescription.sections.right.map((i, k) => {
            return (
              <div key={k} className="item">
                {ReactHtmlParser(i.content)}
              </div>
            );
          })}
        </div>
      </Block>

      <Boxes {...procedures}></Boxes>
    </div>
  );
};

const DentalTourismPage = ({ data }) => {
  
  const {
    templateKey,
    language,
    title,
    description,
    keywords,
    redirects,
    hero,
    gallerySteps,
    heading,
    prices,
    blocksDescription,
    parallax,
    routes,
    procedures,
    anexes,
    form,
  } = data.markdownRemark.frontmatter;

  const [isContactWidgetOpen, setIsContactWidgetOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait,setIsPortrait] = useState(false);

  const changeOpenWidget = () => {
    /*if(isMobile){
      if(isPortrait){
        setIsContactWidgetOpen(!isContactWidgetOpen);
      }
    }else{
      setIsContactWidgetOpen(!isContactWidgetOpen);
    } */
    setIsContactWidgetOpen(!isContactWidgetOpen);
  };

  useLayoutEffect(() => {

    if(typeof window !== "undefined"){
      
      const updateSize = () => {

          let width = window.screen.width;
          let height = window.screen.height;

          if(width < 1026){
          
            setIsMobile(true);
          }else{
          
            setIsMobile(false);
          }

          if(height > width){
         
            setIsPortrait(true);
          }else{
        
            setIsPortrait(false);
          }
      };

      window.addEventListener("resize",updateSize);
    }
  });

  return (
    <Layout>
      <SetLang language={language} link={redirects} />
      <SEO
        title={title}
        lang={language}
        description={description}
        keywords={keywords}
      />
      <DentalTourismPageTemplate
        {...{
          templateKey,
          language,
          title,
          blocksDescription,
          redirects,
          hero,
          heading,
          prices,
          parallax,
          anexes,
          gallerySteps,
          procedures,
          form,
          routes,
        }}
      />

      <div>
        <ContactWidget changeOpenWidget={changeOpenWidget}/>

        {isContactWidgetOpen == true ? (
          <>
           <ContactPopUp changeOpenWidget={changeOpenWidget} />
          </>
        ) : <></>}

      </div>
    </Layout>
  );
};

export default DentalTourismPage;

export const pageQuery = graphql`
  query DentalTourismPage($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "dental-tourism" } }
    ) {
      frontmatter {
        language
        title
        description
        keywords
        redirects
        heading {
          display
          content
        }
        hero {
          background {
            scaleOnReveal
            img {
              childImageSharp {
                fluid(quality: 100, srcSetBreakpoints: [1500]) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            isParallax
          }
          anim {
            display
            type
          }
          height
          indicator
          portraitPosition
          content {
            position
            body
          }
        }
        prices {
          footer {
            image {
              childImageSharp {
                fluid(srcSetBreakpoints: [400], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            to
          }

          rows {
            title
            icon
            rows {
              procedure
              price
              currency
            }
            link {
              title
              to
            }
          }
        }
        blocksDescription {
          sections {
            left {
              content
              image {
                childImageSharp {
                  fluid(srcSetBreakpoints: [600], quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            right {
              content
            }
          }
        }
        routes {
          title
          image
          icons {
            clock
            currency
          }
          footer
          departures {
            from
            flag
            time
            cost
            visa
          }
        }
        anexes {
          display
          items {
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [800], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            content
            footer {
              icon {
                display
                img {
                  childImageSharp {
                    fluid(srcSetBreakpoints: [400], quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              link {
                display
                to
                placeholder
              }
            }
          }
        }

        form {
          title
          background {
            childImageSharp {
              fluid(srcSetBreakpoints: [1500], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        parallax {
          portraitPosition
          img {
            childImageSharp {
              fluid(srcSetBreakpoints: [1500], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          content
        }
        gallerySteps {
          title
          steps {
            title
            image {
              childImageSharp {
                fluid(srcSetBreakpoints: [550], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        procedures {
          title
          procedures {
            title
            to
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [550], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
